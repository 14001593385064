<template>
    <div class="row flex-nowrap">
        <div class="col-auto px-0">
            <Menu default-menu="null">
                <template #section-one-heading="{collapsed}">
                    <MenuHeading
                        title="Mapping"
                        icon="map"
                        :collapsed="collapsed"
                    />
                </template>

                <template #section-one-items="{collapsed}">
                    <MenuItem
                        href="#"
                        icon="user"
                        :collapsed="collapsed"
                        title="Data Maps"
                    >
                        <template
                            #default="{id}"
                        >
                            <RouterLink
                                :id="id"
                                class="admin-menu-link"
                                :to="{name: 'admin.datamaps.list'}"
                            >
                                <FontAwesomeIcon
                                    icon="expand-arrows-alt"
                                    class="fa fa-lg fa-fw"
                                />
                                <span class="admin-menu-link-label">
                                    Data Maps
                                </span>
                            </RouterLink>
                        </template>
                    </MenuItem>
                </template>
            </Menu>
        </div>

        <div class="col px-0 d-flex flex-column">
            <Header
                title="Data Maps"
                @toggled-mode="toggleMode($event)"
            />

            <RouterView />

            <div class="row footer m-0 mt-auto">
                <div class="col-4">
                    <a
                        href="https://ticket.imtins.com/status/endpoints"
                        class="btn btn-link p-0"
                    >
                        Platform Status
                    </a>
                </div>

                <div class="col text-muted text-center">
                    <span class="copyright">&copy;{{ copyrightText }}</span>
                </div>

                <div class="col-4 text-right">
                    <button
                        ref="backToTop"
                        v-scroll-to="'#app'"
                        class="btn btn-link btn-back-to-top p-0"
                    >
                        <FontAwesomeIcon
                            icon="caret-up"
                        />
                        Back to top
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from '@imt/vue-admin-menus/src/components/Header.vue';
    import Menu from '@imt/vue-admin-menus/src/components/Menu.vue';
    import MenuHeading from '@imt/vue-admin-menus/src/components/MenuHeading.vue';
    import MenuItem from '@imt/vue-admin-menus/src/components/MenuItem.vue';
    import dayjs from 'dayjs';

    export default {
        name: 'MBase',
        components: {
            Header,
            Menu,
            MenuHeading,
            MenuItem,
        },
        metaInfo() {
            const title = this.pageTitle;

            return {
                title,
                titleTemplate: '%s | Data Map Admin | IMT Insurance',
            };
        },
        data() {
            return {
                adminUrls: {},
            };
        },
        computed: {
            copyrightText: () => `Copyright ${dayjs().year()} IMT Insurance. All rights reserved.`,
            darkMode() {
                return this.mode === 'dark';
            },
            pageTitle() {
                return this.$route.meta.title || '';
            },
        },
    };
</script>

<style lang="sass">
    @import "~@imt/vue-kit-car/src/sass/kit-car"
</style>
